<template>
    <div id="nvSnapshotOverview">
        <v-container fluid>
            <v-banner v-if="isInstanceObserver" class="mb-5">
                <v-avatar slot="icon" color="white" size="30"><v-icon large color="info">info</v-icon></v-avatar>
                <span class="subtitle-1">
                    You have an observer role in this space which does not allow you to work with its content. To gain access please request a viewer role.
                </span>
                <template v-slot:actions>
                    <v-btn
                        @click="submitRequest(invitationAndRequestRoleTypes.INSTANCE_ROLE, roleCodes.INSTANCE_VIEWER)"
                        target="_blank"
                        color="primary"
                        :loading="requestingRole">
                        Request role
                    </v-btn>
                </template>
            </v-banner>
            <v-banner
                v-if="
                    privateInstances.length > tours.PRESTART_MINIMUM_INSTANCE_COUNT &&
                    schedules.length === 0 &&
                    !currentSpaceArchived &&
                    isSpaceAdmin &&
                    isMasterInstance &&
                    currentSpaceType === spaceTypes.EDUCATION_SPACE
                "
                class="mb-5">
                <v-avatar slot="icon" color="white" size="30"><v-icon large color="info">info</v-icon></v-avatar>
                <span class="subtitle-1">
                    No upcoming scheduled application starts defined. For optimal application start speed, please specify all known times when many students are
                    expected to start working on Nuvolos simultaneously.
                </span>
                <template v-slot:actions>
                    <v-btn
                        :to="{
                            name: 'snapshot-applications'
                        }"
                        color="primary">
                        Go to apps for scheduling
                    </v-btn>
                </template>
            </v-banner>
            <v-banner v-if="!fetchingInstances && currentSpaceArchived && !isMasterInstance" class="mb-5">
                <v-avatar slot="icon" color="white" size="30"><v-icon large color="info">info</v-icon></v-avatar>
                <span v-if="isDevelopment && snapshotFilesystemPrefix && instanceRearchiveAfterTimestamp" class="subtitle-1">
                    This space has been archived. Contents of Current State are available for temporary access but will be permanently deleted after
                    {{ instanceRearchiveAfterTimestamp | dateTimeToHuman }}
                </span>
                <span v-else class="subtitle-1">
                    This instance has been archived. Contents may be restored for temporary access but prolonged active use is not supported.
                </span>
                <template v-slot:actions>
                    <v-btn href="https://docs.nuvolos.cloud/user-guides/education-guides/archiving-your-course" elevation="0" target="_blank" color="primary">
                        Learn more
                    </v-btn>
                </template>
            </v-banner>
            <v-banner v-if="isSpaceAdmin && archivalWithinThirtyDays && !currentSpaceArchived" class="mb-5">
                <v-avatar slot="icon" color="white" size="30"><v-icon large color="info">info</v-icon></v-avatar>
                <span class="subtitle-1">
                    This space is scheduled to be archived on
                    <strong>{{ spaceArchivalDate }}</strong>
                    . You can set a new archival date in the space's details using the
                    <v-icon dense>info</v-icon>
                    icon below.
                </span>
            </v-banner>
            <div class="d-flex flex-column">
                <div class="d-flex align-center">
                    <span type="list-item" v-if="isSpaceAdmin && !isSpaceNameFieldMounted" class="subtitle-2 secondary--text">Loading space name....</span>
                    <EditableArea
                        v-if="spaceLongName && isSpaceAdmin"
                        @mounted="isSpaceNameFieldMounted = true"
                        :value="spaceLongName"
                        :apiURL="'/spaces/' + this.$route.params.sid"
                        successMessage="Space name successfully updated!"
                        editAttr="long_id"
                        fontClass="title font-weight-bold"
                        editAttrFullName="space name"
                        defaultValue="Space name not available"
                        color="white"
                        :showEditIcon="isSpaceAdmin"
                        :id="parseInt($route.params.sid, 10)"
                        :finally="
                            () => {
                                this.$store.dispatch('orgStore/fetchOrgSpaces', this.$route.params.oid)
                            }
                        "></EditableArea>
                    <span v-else-if="!isSpaceAdmin" class="secondary--text title font-weight-bold">
                        {{ $store.getters['spaceStore/spaceLongNameById']($route.params.sid) }}
                    </span>
                    <span v-else>Loading space name...</span>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" small text @click="detailsDrawer = true" icon><v-icon>info</v-icon></v-btn>
                        </template>
                        <span>Show details</span>
                    </v-tooltip>
                </div>
                <span type="list-item" v-if="isSpaceAdmin && !isSpaceNameFieldMounted">Loading space description....</span>
                <EditableArea
                    v-if="isSpaceAdmin"
                    @mounted="isSpaceDescriptionFieldMouted = true"
                    :value="spaceDescription"
                    :apiURL="'/spaces/' + this.$route.params.sid"
                    successMessage="Space description successfully updated!"
                    editAttr="description"
                    editAttrFullName="space description"
                    color="white"
                    defaultValue="Description not available for this space"
                    :showEditIcon="isSpaceAdmin"
                    :id="parseInt($route.params.sid, 10)"
                    :finally="
                        () => {
                            this.$store.dispatch('orgStore/fetchOrgSpaces', this.$route.params.oid)
                        }
                    "></EditableArea>
                <span v-else class="subtitle-2 secondary--text">{{ $store.getters['spaceStore/spaceDescriptionById'](this.$route.params.sid) }}</span>
            </div>
        </v-container>
        <v-alert v-if="bundleCreated" text prominent type="info">
            <v-row align="center">
                <v-col class="grow">
                    <div class="font-weight-bold">Did you know?</div>
                    <div>You can recall distributed files easily if needed.</div>
                </v-col>
                <v-col class="shrink">
                    <v-btn href="https://docs.nuvolos.cloud/features/object-distribution#recall-files" target="_blank" outlined small color="info">
                        Learn more
                    </v-btn>
                </v-col>
            </v-row>
        </v-alert>
        <v-container fluid v-if="assignmentsList.length">
            <v-row>
                <v-col v-for="(assignment, idx) in assignmentsList" xs="12" md="4" xl="3" :key="idx">
                    <TheSnapshotAssignmentTile :assignmentData="assignment" />
                </v-col>
            </v-row>
        </v-container>
        <v-container fluid>
            <v-row>
                <v-col md="8">
                    <v-card style="min-height: 430px" :color="readmeMdWithDefault === '' && !fetchingReadme ? 'white' : ''">
                        <v-card-text>
                            <div v-if="fetchingReadme">
                                <v-skeleton-loader type="article@2"></v-skeleton-loader>
                                <v-skeleton-loader type="paragraph" class="ml-4"></v-skeleton-loader>
                            </div>
                            <div v-else>
                                <div style="position: absolute; top: 0; right: 0">
                                    <TheSnapshotFileEditor
                                        v-if="isDevelopment && isInstanceEditor"
                                        :isMarkdownFile="true"
                                        :isCreateReadmeFile="readmeData === null"
                                        :endpoint="`/snapshots/${$route.params.snid}/fs/files`"
                                        :fileData="readmeData || {}"
                                        language="markdown"
                                        :newReadmeFileContent="newReadmeFileContent"
                                        @readmeFileUpdated="fetchReadmeAndVideos($route.params.snid)"
                                        @readmeFileCreated="fetchReadmeAndVideos($route.params.snid)"></TheSnapshotFileEditor>
                                </div>
                                <div v-if="failedToFetchReadme" class="d-flex align-center text-center justify-center align-center" style="min-height: 400px">
                                    <template v-if="isDevelopment">
                                        <p v-if="isInstanceArchived">
                                            This instance has been archived so there are no files in its Current State. Please
                                            <a @click="goToSnapshots()">restore</a>
                                            a snapshot to the Current State to work with files.
                                        </p>
                                        <div v-else>Failed to fetch files for this instance, please contact support.</div>
                                    </template>
                                    <template v-else>
                                        <p>
                                            This snapshot is in cold storage, please
                                            <a @click="goToSnapshots()">restore</a>
                                            it to the Current State to work with its files.
                                        </p>
                                    </template>
                                </div>
                                <MarkdownViewer v-else-if="readmeMdWithDefault" :editorText="readmeMdWithDefault" />
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col v-if="!showSidebarLoader" md="4">
                    <template v-if="showCheckList">
                        <TheSnapshotCourseCheckList :schedules="schedules" @mounted="courseChecklistMounted = true" />
                    </template>
                    <v-card flat v-if="videoURLs">
                        <v-divider class="my-3" />
                        <v-card-title>
                            <div class="d-flex align-center">
                                <h5 class="secondary--text">Videos</h5>
                                <v-btn color="primary" small text @click="showVideoModal">See All</v-btn>
                            </div>
                        </v-card-title>
                        <v-card-text class="pa-0">
                            <div id="nvVideoPreview">
                                <iframe
                                    :src="videoURLs[videoURLs.length - 1].url"
                                    :style="{ width: '100%', 'background-color': 'black', height: videoHeight + 'px' }"
                                    frameborder="0"
                                    allow="autoplay; fullscreen"
                                    allowfullscreen
                                    v-resize="onResize"></iframe>
                            </div>
                        </v-card-text>
                    </v-card>
                    <template v-if="!showCheckList && !isInstanceObserver">
                        <v-card flat color="transparent">
                            <v-card-title>
                                <h5 class="secondary--text">Resources</h5>
                            </v-card-title>
                            <v-card-text class="pa-0">
                                <v-list nav class="pa-0" color="transparent">
                                    <v-list-item
                                        :to="{
                                            name: 'snapshot-files',
                                            params: {
                                                oid: $route.params.oid,
                                                sid: $route.params.sid,
                                                iid: $route.params.iid,
                                                snid: $route.params.snid,
                                                fileArea: fileAreaType,
                                                localPath: currentFileLocalPath
                                            }
                                        }"
                                        :two-line="screenSize === 'large' || screenSize === 'small'"
                                        :three-line="screenSize === 'medium'"
                                        e2e-snapshot-resources-files>
                                        <v-list-item-avatar>
                                            <v-icon large>folder</v-icon>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <h4 class="mr-2 secondary--text">Files</h4>
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                <span class="caption mr-3">Browse and manage files</span>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>

                                    <v-list-item
                                        :to="{
                                            name: 'snapshot-tables',
                                            params: { oid: $route.params.oid, sid: $route.params.sid, iid: $route.params.iid, snid: $route.params.snid }
                                        }"
                                        :two-line="screenSize === 'large' || screenSize === 'small'"
                                        :three-line="screenSize === 'medium'"
                                        e2e-snapshot-resources-tables>
                                        <v-list-item-avatar>
                                            <v-icon large>mdi-table-large</v-icon>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <h4 class="mr-2 secondary--text">Tables</h4>
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                <span class="caption mr-3">Query and export data</span>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item
                                        :to="{
                                            name: 'snapshot-applications',
                                            params: { oid: $route.params.oid, sid: $route.params.sid, iid: $route.params.iid, snid: $route.params.snid }
                                        }"
                                        :two-line="screenSize === 'large' || screenSize === 'small'"
                                        :three-line="screenSize === 'medium'"
                                        e2e-snapshot-resources-applications>
                                        <v-list-item-avatar>
                                            <v-icon large>desktop_windows</v-icon>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <h4 class="mr-2 secondary--text">Applications</h4>
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                <span class="caption">Create and launch applications</span>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item
                                        :to="{
                                            name: 'instance-snapshots',
                                            params: { oid: $route.params.oid, sid: $route.params.sid, iid: $route.params.iid, snid: $route.params.snid }
                                        }"
                                        :two-line="screenSize === 'large' || screenSize === 'small'"
                                        :three-line="screenSize === 'medium'"
                                        e2e-snapshot-resources-snapshots>
                                        <v-list-item-avatar>
                                            <v-icon large>camera_alt</v-icon>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <h4 class="mr-2 secondary--text">Snapshots</h4>
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                <span v-if="currentSpaceType === spaceTypes.EDUCATION_SPACE" class="caption">
                                                    Review timeline of saved course versions
                                                </span>
                                                <span v-else-if="currentSpaceType === spaceTypes.RESEARCH_SPACE" class="caption">
                                                    Review timeline of saved project versions
                                                </span>
                                                <span v-if="currentSpaceType === spaceTypes.VENDOR_SPACE" class="caption">
                                                    Review timeline of saved dataset versions
                                                </span>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-card-text>
                        </v-card>
                    </template>
                </v-col>
                <v-col v-else>
                    <v-card flat>
                        <v-card-text>
                            <v-skeleton-loader type="article@2"></v-skeleton-loader>
                            <v-skeleton-loader type="paragraph" class="ml-4"></v-skeleton-loader>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-dialog v-model="showVideos" width="60vw">
            <v-card>
                <v-card-title>Videos</v-card-title>
                <v-card-text>
                    <v-carousel v-model="videoModel" v-if="videoURLs" height="55vh" hide-delimiters>
                        <v-carousel-item v-for="(video, i) in videoURLs" :key="video.url">
                            <div class="mb-2 secondary--text">{{ video.description }}</div>
                            <div class="d-flex justify-center">
                                <iframe
                                    :src="videoURLs[i].url"
                                    :style="{ width: '50vw', 'background-color': 'black', height: '50vh' }"
                                    frameborder="0"
                                    allow="autoplay; fullscreen"
                                    allowfullscreen></iframe>
                            </div>
                        </v-carousel-item>
                    </v-carousel>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-navigation-drawer v-model="detailsDrawer" fixed right temporary touchless :width="detailsDrawerWidth">
            <div class="d-flex align-center pa-3">
                <span class="secondary--text subtitle-2 font-weight-medium" v-if="currentSpaceType === spaceTypes.EDUCATION_SPACE">Course details</span>
                <span class="secondary--text subtitle-2 font-weight-medium" v-else-if="currentSpaceType === spaceTypes.RESEARCH_SPACE">Project details</span>
                <span class="secondary--text subtitle-2 font-weight-medium" v-else-if="currentSpaceType === spaceTypes.VENDOR_SPACE">Dataset details</span>
                <v-spacer />
                <v-btn icon @click="detailsDrawer = false">
                    <v-icon>close</v-icon>
                </v-btn>
            </div>
            <v-divider></v-divider>
            <v-alert type="info" text prominent v-if="currentSpaceType === spaceTypes.EDUCATION_SPACE && isMasterInstance && isInstanceEditor">
                <v-row align="center">
                    <v-col class="grow">Material in the master instance is not directly visible to students, it has to be distributed.</v-col>
                    <v-col class="shrink">
                        <v-btn
                            outlined
                            href="https://docs.nuvolos.cloud/getting-started/nuvolos-basic-concepts/distribution#distributing-teaching-material-to-students"
                            target="_blank"
                            small
                            color="info">
                            Learn more
                        </v-btn>
                    </v-col>
                </v-row>
            </v-alert>
            <v-row align="center">
                <v-col cols="4">
                    <v-subheader>Visibility</v-subheader>
                </v-col>
                <v-col>
                    <span class="subtitle-2 secondary--text" v-if="currentSpaceVisibility === spaceVisibilityCodes.PUBLIC_SPACE">Public</span>
                    <span class="subtitle-2 secondary--text" v-if="currentSpaceVisibility === spaceVisibilityCodes.PRIVATE_SPACE">Private</span>
                    <span class="subtitle-2 secondary--text" v-if="currentSpaceVisibility === spaceVisibilityCodes.FACULTY_ONLY_SPACE">Faculty only</span>
                </v-col>
            </v-row>
            <v-row align="center">
                <v-col cols="4">
                    <v-subheader>Lifecycle phase</v-subheader>
                </v-col>
                <v-col>
                    <span class="subtitle-2 secondary--text" v-if="!currentSpaceArchived">Live</span>
                    <span class="subtitle-2 secondary--text" v-if="currentSpaceArchived">Archived on {{ spaceArchiveTimestamp | dateTimeToHuman }}</span>
                </v-col>
            </v-row>
            <v-row v-if="isSpaceAdmin" align="center">
                <v-col cols="4">
                    <v-subheader>Space role</v-subheader>
                </v-col>
                <v-col>
                    <div class="d-flex align-center subtitle-2 secondary--text">
                        <v-icon small color="secondary">security</v-icon>
                        Administrator
                    </div>
                </v-col>
            </v-row>
            <v-row align="center">
                <v-col cols="4">
                    <v-subheader>Instance name</v-subheader>
                </v-col>
                <v-col>
                    <EditableArea
                        v-if="isInstanceEditor"
                        :value="instanceLongName"
                        :apiURL="'/instances/' + this.$route.params.iid"
                        successMessage="Instance name successfully updated!"
                        editAttr="long_id"
                        editAttrFullName="instance name"
                        defaultValue="Instance name not available"
                        color="white"
                        :showEditIcon="isInstanceEditor && canDeleteInstanceOrEditFields"
                        :id="parseInt($route.params.sid, 10)"
                        :finally="
                            () => {
                                this.$store.dispatch('spaceStore/fetchSpaceInstances', this.$route.params.sid)
                            }
                        "></EditableArea>
                    <span v-else-if="!isInstanceEditor" class="secondary--text subtitle-2">{{ instanceName }}</span>
                </v-col>
            </v-row>
            <v-row align="center">
                <v-col cols="4">
                    <v-subheader>Instance description</v-subheader>
                </v-col>
                <v-col>
                    <EditableArea
                        :value="currentInstanceDescription"
                        v-if="isInstanceEditor"
                        :apiURL="'/instances/' + this.$route.params.iid"
                        successMessage="Instance description successfully updated!"
                        editAttr="description"
                        editAttrFullName="instance description"
                        defaultValue="Instance description not available"
                        color="white"
                        :showEditIcon="isInstanceEditor && canDeleteInstanceOrEditFields"
                        :id="parseInt($route.params.sid, 10)"
                        :finally="
                            () => {
                                this.$store.dispatch('spaceStore/fetchSpaceInstances', this.$route.params.sid)
                            }
                        "></EditableArea>
                    <span class="secondary--text subtitle-2" v-else-if="!isInstanceEditor">{{ currentInstanceDescription }}</span>
                </v-col>
            </v-row>
            <v-row align="center">
                <v-col cols="4">
                    <v-subheader>Archive by date</v-subheader>
                </v-col>
                <v-col>
                    <EditableArea
                        :value="spaceArchivalDate"
                        type="date"
                        :max="furthestArchivalDate"
                        v-if="isInstanceEditor"
                        :apiURL="'/spaces/' + this.$route.params.sid"
                        successMessage="Archival date successfully updated!"
                        editAttr="archive_by_date"
                        editAttrFullName="date of archival"
                        defaultValue="9999-12-31"
                        color="white"
                        :showEditIcon="isSpaceAdmin && !currentSpaceArchived"
                        :id="parseInt($route.params.sid, 10)"
                        :finally="
                            () => {
                                this.$store.dispatch('orgStore/fetchOrgSpaces', this.$route.params.oid)
                            }
                        "></EditableArea>
                    <span class="secondary--text subtitle-2" v-else-if="!isInstanceEditor">{{ spaceArchivalDate }}</span>
                </v-col>
            </v-row>
            <template v-slot:append>
                <v-divider></v-divider>
                <div class="d-flex align-center">
                    <v-list dense nav>
                        <v-list-item>
                            <v-list-item-title>
                                <LeaveDialog
                                    :levelLeavingTitle="
                                        currentSpaceType === spaceTypes.EDUCATION_SPACE
                                            ? 'leave course'
                                            : currentSpaceType === spaceTypes.RESEARCH_SPACE
                                            ? 'leave project'
                                            : 'leave space'
                                    "
                                    :hierarchicalLevel="nuvolosObjectTypes.SPACE" />
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </div>
            </template>
        </v-navigation-drawer>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { enumsData } from '@/mixins/enums'
import { assignmentMethods } from '@/mixins/assignments'
import { queryData } from '@/mixins/query'
import { snapshotEnums } from '@/mixins/snapshot'
import { invitationsAndRequests } from '@/mixins/invitationsAndRequests'
import { readmeMdEdu, readmeMdRes, readmeMdData } from '@/modules/snapshot/readme_md.js'
import 'github-markdown-css/github-markdown.css'
import Shepherd from 'shepherd.js'
import tour from '@/mixins/tour'
import { fetchAppStartupSchedules } from '@/apis'
import { DateTime } from 'luxon'

const EditableArea = () => import('@/components/EditableArea')
const LeaveDialog = () => import('@/components/LeaveDialog')
const TheSnapshotFileEditor = () => import('../components/TheSnapshotFileEditor')
const MarkdownViewer = () => import('@/components/MarkdownViewer')
const TheSnapshotAssignmentTile = () => import('../components/TheSnapshotAssignmentTile')
const TheSnapshotCourseCheckList = () => import('../components/TheSnapshotCourseCheckList')

export default {
    mixins: [enumsData, snapshotEnums, assignmentMethods, queryData, invitationsAndRequests, tour],
    components: {
        LeaveDialog,
        TheSnapshotFileEditor,
        MarkdownViewer,
        TheSnapshotAssignmentTile,
        TheSnapshotCourseCheckList,
        EditableArea
    },
    data() {
        return {
            detailsDrawer: false,
            settingsModal: false,
            spaceNameFieldMounted: false,
            overviewTab: 0,
            readme_md_content: '',
            fetchingReadme: true,
            videoHeight: 200,
            videoURLs: null,
            showVideos: false,
            videoModel: 0,
            readmeData: null,
            failedToFetchReadme: false,
            isSpaceNameFieldMounted: false,
            isSpaceDescriptionFieldMouted: false,
            courseChecklistMounted: false,
            fetchingVideosAndReadme: false,
            assignments: [
                {
                    long_id: 'Assignment 1',
                    bid: 1,
                    description: 'This is the description of assignment 1',
                    status: 'submitted',
                    allow_handins_until: '2021-03-02T00:07:07+00:00',
                    objects: {
                        applications: [1, 3, 4],
                        files: ['file1.txt', 'file2.txt'],
                        tables: ['table1', 'table2']
                    }
                },
                {
                    long_id: 'Assignment 2',
                    bid: 2,
                    description: 'This is the description of assignment 2',
                    status: 'tosubmit',
                    allow_handins_until: '2021-04-10T00:07:07+00:00',
                    objects: {
                        applications: [1, 3, 4],
                        files: ['file1.txt', 'file2.txt'],
                        tables: ['table1', 'table2']
                    }
                },
                {
                    long_id: 'Assignment 3',
                    bid: 3,
                    description: 'This is the description of assignment 3',
                    status: 'tosubmit',
                    allow_handins_until: '2021-03-03T00:07:07+00:00',
                    objects: {
                        applications: [1, 3, 4],
                        files: ['file1.txt', 'file2.txt'],
                        tables: ['table1', 'table2']
                    }
                }
            ],
            tourId: 'show_space_tour',
            tourHidden: false,
            schedules: []
        }
    },
    created() {
        this.fetchSchedules()
    },
    computed: {
        ...mapGetters('instanceStore', [
            'isInstanceEditor',
            'currentInstanceDescription',
            'isInstanceObserver',
            'isMasterInstance',
            'canDeleteInstanceOrEditFields',
            'currentInstanceDescription',
            'isInstanceArchived'
        ]),
        ...mapState(['userMetadata', 'fetchingUserInfo']),
        ...mapState('instanceStore', ['instanceSnapshots']),
        ...mapGetters('spaceStore', [
            'isSpaceAdmin',
            'currentSpaceType',
            'currentSpaceVisibility',
            'currentSpaceArchived',
            'privateInstances',
            'spaceArchivalDate',
            'spaceCreationDate'
        ]),
        ...mapGetters('snapshotStore', ['isDevelopment', 'currentFileLocalPath']),
        ...mapGetters('orgStore', ['isOrgManager']),
        ...mapState('orgStore', ['fetchingSpaces']),
        ...mapState('spaceStore', ['spaceBundles', 'fetchingInstances', 'fetchingSpaceBundles']),
        ...mapState('snapshotStore', ['fileAreaType', 'filesFetching']),
        ...mapState(['bundleCreated']),
        showSidebarLoader() {
            return (
                (!this.showCheckList || (this.showCheckList && this.courseChecklistMounted)) &&
                (this.filesFetching || this.fetchingSpaceBundles || this.fetchingInstances || this.fetchingVideosAndReadme || this.fetchingSpaces)
            )
        },
        newReadmeFileContent() {
            const content = `Add your README content here or remove it completely to restore the default information.`
            return content
        },
        showCheckList() {
            return this.currentSpaceType === this.spaceTypes.EDUCATION_SPACE && this.isMasterInstance && this.isDevelopment && this.isSpaceAdmin
        },
        spaceLongName() {
            return this.$store.getters['spaceStore/spaceLongNameById'](this.$route.params.sid)
        },
        spaceDescription() {
            return this.$store.getters['spaceStore/spaceDescriptionById'](this.$route.params.sid)
        },
        spaceArchiveTimestamp() {
            return this.$store.getters['spaceStore/spaceArchiveTimestampById'](this.$route.params.sid)
        },
        instanceLongName() {
            return this.$store.getters['instanceStore/instanceLongNameById'](this.$route.params.iid)
        },
        instanceRearchiveAfterTimestamp() {
            return this.$store.getters['instanceStore/instanceRearchiveAfterTimestamp'](this.$route.params.iid)
        },
        assignmentsList() {
            if (this.spaceBundles.length) {
                return this.spaceBundles.filter(bundle => bundle.bundle_type_name === this.distributionReasons.ASSIGNMENT)
            }
            return []
        },
        screenSize() {
            if (this.$vuetify.breakpoint.smAndDown) {
                return 'small'
            } else if (this.$vuetify.breakpoint.mdAndDown) {
                return 'medium'
            } else if (this.$vuetify.breakpoint.lgAndup) {
                return 'large'
            }
            return 'large'
        },
        detailsDrawerWidth() {
            return this.$vuetify.breakpoint.mdAndUp ? 640 : 320
        },
        snapshotName() {
            return this.$store.getters['snapshotStore/snapshotLongNameById'](this.$route.params.snid)
        },
        snapshotFilesystemPrefix() {
            return this.$store.getters['snapshotStore/snapshotFilesystemPrefixById'](this.$route.params.snid)
        },
        instanceName() {
            return this.$store.getters['instanceStore/instanceLongNameById'](this.$route.params.iid)
        },
        readmeMdWithDefault() {
            if (this.readme_md_content) {
                return this.readme_md_content.replace(/\[(.*?)\]\(files(.*?)\)/g, '[$1](files/files$2)')
            }
            if (this.currentSpaceType === this.spaceTypes.EDUCATION_SPACE) {
                return readmeMdEdu
            } else if (this.currentSpaceType === this.spaceTypes.RESEARCH_SPACE) {
                return readmeMdRes
            }
            return readmeMdData
        },
        showSpaceTour() {
            return (
                !this.fetchingSpaces &&
                !this.fetchingInstances &&
                !this.fetchingSpaceBundles &&
                !this.filesFetching &&
                !this.fetchingUserInfo &&
                this.userMetadata.show_space_tour
            )
        },
        furthestArchivalDate() {
            return DateTime.fromISO(this.spaceCreationDate).plus({ years: 1 }).toISODate()
        },
        archivalWithinThirtyDays() {
            const dt = DateTime.fromISO(this.spaceArchivalDate)
            const now = DateTime.now()
            return dt <= now.plus({ days: 30 })
        }
    },
    methods: {
        queryTable(tableName) {
            this.$router.push({
                name: 'snapshot-tables',
                params: {
                    oid: this.$route.params.oid,
                    sid: this.$route.params.sid,
                    iid: this.$route.params.iid,
                    snid: this.$route.params.snid
                },
                query: { tab: this.tableViewTabNames.QUERYBUILDER, table: tableName }
            })
        },
        onResize() {
            if (document.getElementById('nvVideoPreview')) {
                this.videoHeight = Math.round(document.getElementById('nvVideoPreview').offsetWidth * 0.75)
            }
        },
        showVideoModal() {
            this.showVideos = true
        },
        goToSnapshots() {
            this.$router.push({
                name: 'instance-snapshots',
                params: {
                    oid: this.$route.params.oid,
                    sid: this.$route.params.sid,
                    iid: this.$route.params.iid,
                    snid: this.$route.params.snid
                }
            })
        },
        fetchReadmeAndVideos(snid) {
            this.fetchingVideosAndReadme = true
            const baseURL = `/snapshots/${snid}/fs/files`
            this.readmeData = null
            this.fetchingReadme = true
            this.failedToFetchReadme = false
            this.$axios
                .get(baseURL)
                .then(response => {
                    let hasREADME = false
                    let hasVIDEOS = false
                    response.data.forEach(s => {
                        if (s.short_id.toLowerCase() === 'readme.md') {
                            hasREADME = true
                            this.readmeData = s
                            this.$axios
                                .get(`/files/${s.fid}/download`, {
                                    timeout: 10000,
                                    responseType: 'blob' // important
                                })
                                .then(response => {
                                    const reader = new FileReader()
                                    reader.addEventListener('loadend', e => {
                                        const text = e.target.result
                                        this.readme_md_content = text
                                    })
                                    reader.readAsText(response.data)
                                })
                                .catch(e => {
                                    console.log(e)
                                    this.readme_md_content = ''
                                })
                                .finally(() => {
                                    window.setTimeout(() => (this.fetchingReadme = false), 1000)
                                })
                        }
                        if (s.short_id.toLowerCase() === '.videos.json') {
                            hasVIDEOS = true
                            this.$axios
                                .get(`/files/${s.fid}/download`, {
                                    timeout: 10000,
                                    responseType: 'blob' // important
                                })
                                .then(response => {
                                    const reader = new FileReader()
                                    reader.addEventListener('loadend', e => {
                                        const text = e.target.result
                                        this.videoURLs = JSON.parse(text).nvVIDEOS
                                        this.$nextTick(() => {
                                            this.onResize()
                                        })
                                    })
                                    reader.readAsText(response.data)
                                })
                                .catch(e => {
                                    console.log(e)
                                })
                        }
                    })
                    if (!hasREADME) {
                        this.fetchingReadme = false
                        this.readme_md_content = ''
                    }
                    if (!hasVIDEOS) {
                        this.videoURLs = null
                    }
                })
                .catch(error => {
                    if (error.response && error.response.data && error.response.data.code === 'item_not_found') {
                        this.failedToFetchReadme = true
                    }
                    this.fetchingReadme = false
                })
                .finally(() => {
                    this.fetchingVideosAndReadme = false
                })
        },
        async fetchSchedules() {
            const { data } = await fetchAppStartupSchedules({ sid: this.$route.params.sid })
            this.schedules = data
        }
    },
    watch: {
        '$route.params.snid': {
            handler: function (to, from) {
                this.fetchReadmeAndVideos(to)
            },
            immediate: true
        },
        showSpaceTour: {
            handler: function (show) {
                if (show) {
                    this.$nextTick(() => {
                        this.tour = new Shepherd.Tour({
                            useModalOverlay: true,
                            defaultStepOptions: {
                                cancelIcon: {
                                    enabled: true
                                },
                                scrollTo: { behavior: 'smooth', block: 'center' }
                            }
                        })
                        this.tour.addSteps([
                            {
                                title: 'Space',
                                text: `You discovered your first space! A space is a self-contained environment for working with applications, files, and tables. <br/><br/> Let's go through quickly what a space contains!`,
                                buttons: [
                                    {
                                        action() {
                                            return this.next()
                                        },
                                        text: 'Next'
                                    }
                                ],
                                id: 'step-1'
                            }
                        ])
                        if (this.isSpaceAdmin) {
                            if (this.$vuetify.breakpoint.mdAndUp) {
                                this.tour.addSteps([
                                    {
                                        title: 'Instance',
                                        text: `Spaces consist of one or multiple instances. Instances offer the opportunity to organize parallel workflows or branches of your work.`,
                                        attachTo: {
                                            element: '.shepherd-toolbar-instance',
                                            on: 'bottom'
                                        },
                                        buttons: [
                                            {
                                                action() {
                                                    return this.back()
                                                },
                                                classes: 'shepherd-button-primary',
                                                text: 'Back'
                                            },
                                            {
                                                action() {
                                                    return this.next()
                                                },
                                                text: 'Next'
                                            }
                                        ],
                                        id: 'step-instance'
                                    },
                                    {
                                        title: 'State',
                                        text: `For maximal reproducibility, Nuvolos can store <b>snapshots</b> of the complete instance (files, applications, tables), which you can restore on demand. <br><br>All snapshots are read-only, except for the "current state" in which work happens.`,
                                        attachTo: {
                                            element: '.shepherd-toolbar-snapshot',
                                            on: 'bottom'
                                        },
                                        buttons: [
                                            {
                                                action() {
                                                    return this.back()
                                                },
                                                classes: 'shepherd-button-primary',
                                                text: 'Back'
                                            },
                                            {
                                                action() {
                                                    return this.next()
                                                },
                                                text: 'Next'
                                            }
                                        ],
                                        id: 'step-instance'
                                    }
                                ])
                            }
                            this.tour.addSteps([
                                {
                                    title: 'Invite and Collaborate',
                                    text: `Invite others to your space or instance to collaborate and work together simultaneously. Here, you can manage users, invitations and instances.`,
                                    attachTo: {
                                        element: '.shepherd-space-management',
                                        on: 'right'
                                    },
                                    buttons: [
                                        {
                                            action() {
                                                return this.back()
                                            },
                                            classes: 'shepherd-button-primary',
                                            text: 'Back'
                                        },
                                        {
                                            action() {
                                                return this.next()
                                            },
                                            text: 'Next'
                                        }
                                    ],
                                    id: 'step-2'
                                }
                            ])
                        }
                        this.tour.addSteps([
                            {
                                title: 'Files',
                                text: `Your files live here: upload, download, rename, move and more.`,
                                attachTo: {
                                    element: '.shepherd-space-files',
                                    on: 'right'
                                },
                                buttons: [
                                    {
                                        action() {
                                            return this.back()
                                        },
                                        classes: 'shepherd-button-primary',
                                        text: 'Back'
                                    },
                                    {
                                        action() {
                                            return this.next()
                                        },
                                        text: 'Next'
                                    }
                                ],
                                id: 'step-3'
                            },
                            {
                                title: 'Tables',
                                text: `You can store, document and query table data sets of any size.`,
                                attachTo: {
                                    element: '.shepherd-space-tables',
                                    on: 'right'
                                },
                                buttons: [
                                    {
                                        action() {
                                            return this.back()
                                        },
                                        classes: 'shepherd-button-primary',
                                        text: 'Back'
                                    },
                                    {
                                        action() {
                                            return this.next()
                                        },
                                        text: 'Next'
                                    }
                                ],
                                id: 'step-4'
                            },
                            {
                                title: 'Applications',
                                text: `Install and manage any number of applications in your instance.`,
                                attachTo: {
                                    element: '.shepherd-space-apps',
                                    on: 'right'
                                },
                                buttons: [
                                    {
                                        action() {
                                            return this.back()
                                        },
                                        classes: 'shepherd-button-primary',
                                        text: 'Back'
                                    },
                                    {
                                        action() {
                                            return this.next()
                                        },
                                        text: 'Next'
                                    }
                                ],
                                id: 'step-5'
                            },
                            {
                                title: 'Snapshots',
                                text: `You can create, manage and restore snapshots of your instance. You can even restore only certain objects (files, tables or applications) to your current state.`,
                                attachTo: {
                                    element: '.shepherd-space-snapshots',
                                    on: 'right'
                                },
                                buttons: [
                                    {
                                        action() {
                                            return this.back()
                                        },
                                        classes: 'shepherd-button-primary',
                                        text: 'Back'
                                    },
                                    {
                                        action() {
                                            return this.next()
                                        },
                                        text: 'Next'
                                    }
                                ],
                                id: 'step-6'
                            },
                            {
                                title: 'Staging area',
                                text: `Here, you can stage all objects (files, apps, tables) that you want to distribute to other instances in your or another space.`,
                                attachTo: {
                                    element: '.shepherd-space-stage',
                                    on: 'right'
                                },
                                buttons: [
                                    {
                                        action() {
                                            return this.back()
                                        },
                                        classes: 'shepherd-button-primary',
                                        text: 'Back'
                                    },
                                    {
                                        action() {
                                            return this.complete()
                                        },
                                        text: 'Got it!'
                                    }
                                ],
                                id: 'step-7'
                            }
                        ])

                        this.tour.start()
                    })
                }
            },
            immediate: true
        }
    }
}
</script>
